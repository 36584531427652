import request from '@/utils/request'
import { gConfig } from "../api/Config";

export function getparam(obj) {
  var arr = Object.keys(obj);
  var str = "?";
  for (var i in obj) {
    str += i;
    str += "=";
    str += obj[i];
    str += "&";
  }
  str = str.substr(0, str.length - 1);
  return str;
}

//*******************************
//得到帽型汇总 - 后台
export function GetAdminCapTypeList(data) {
  return request({
    url: gConfig.url_server + 'api/sumAdmin/GetCapTypeList' + getparam(data),
    method: 'get',
  })
}
//得到城市排行汇总 - 后台
export function GetAdminCityList(data) {
  return request({
    url: gConfig.url_server + 'api/sumAdmin/GetCityList' + getparam(data),
    method: 'get',
  })
}
//得到价格区间汇总 - 后台
export function GetAdminPriceRangeList(data) {
  return request({
    url: gConfig.url_server + 'api/sumAdmin/GetPriceRangeList' + getparam(data),
    method: 'get',
  })
}
//得到会员详情 - 后台
export function GetAdminMemInfo(data) {
  return request({
    url: gConfig.url_server + 'api/Admin/Member/GetByPhone' + getparam(data),
    method: 'get',
  })
}
//获取工作流程备注
export function GetListRemarkByWoId(data) {
  return request({
    url: gConfig.url_server + 'api/workflowAdmin/GetListRemarkByWoId' + getparam(data),
    method: 'get',
  })
}
//获取工作流程汇总
export function GetWorkflowSum(data) {
  return request({
    url: gConfig.url_server + 'api/workflowAdmin/GetWorkflowSum' + getparam(data),
    method: 'get',
  })
}
//资详详情
export function GetInformationDetails(data) {
  return request({
    url: gConfig.url_server + 'api/goods/GetInformationDetails' + getparam(data),
    method: 'get',
  })
}
//资讯列表
export function GetInformationList(data) {
  return request({
    url: gConfig.url_server + 'api/goods/GetInformationList' + getparam(data),
    method: 'get',
  })
}
//插画列表
export function GetIllustrationList(data) {
  return request({
    url: gConfig.url_server + 'api/goods/GetIllustrationList' + getparam(data),
    method: 'get',
  })
}
//造型列表
export function GetModelList(data) {
  return request({
    url: gConfig.url_server + 'api/goods/GetModelList' + getparam(data),
    method: 'get',
  })
}
//首页展示图片（造型、插画）
export function GetShwoIngIdx() {
  return request({
    url: gConfig.url_server + 'api/index/GetShwoIngIdx',
    method: 'get',
  })
}
//首页模特列表
export function GetModelListIdx() {
  return request({
    url: gConfig.url_server + 'api/index/GetModelList',
    method: 'get',
  })
}
//添加天猫商品
export function AddTmallGoods(data) {
  return request({
    url: gConfig.url_server + 'api/tmallGoodsAdmin/add',
    method: 'post',
    data
  })
}
//获取天猫商品列表
export function GetTmallGoodsList(data) {
  return request({
    url: gConfig.url_server + 'api/tmallGoodsAdmin/GetList' + getparam(data),
    method: 'get',
  })
}
//获取天猫商品列表
export function GetTmallGoodsListQ(data) {
  return request({
    url: gConfig.url_server + 'api/tmallGoods/GetList' + getparam(data),
    method: 'get',
  })
}
//获取天猫商品列表数量
export function GetTmallGoodsListCount(data) {
  return request({
    url: gConfig.url_server + 'api/tmallGoodsAdmin/GetListCount' + getparam(data),
    method: 'get',
  })
}
//获取天猫商品列表数量
export function GetTmallGoodsListCountQ(data) {
  return request({
    url: gConfig.url_server + 'api/tmallGoods/GetListCount' + getparam(data),
    method: 'get',
  })
}
//更新天猫商品状态
export function UpdateTmallGoodsStatus(data) {
  return request({
    url: gConfig.url_server + 'api/tmallGoodsAdmin/UpdateStatus',
    method: 'post',
    data
  })
}
//更新天猫商品排序
export function UpdateTmallGoodsSort(data) {
  return request({
    url: gConfig.url_server + 'api/tmallGoodsAdmin/UpdateSort',
    method: 'post',
    data
  })
}
//更新天猫系列排序
export function UpdateTmallGoodsSortSeries(data) {
  return request({
    url: gConfig.url_server + 'api/tmallGoodsAdmin/UpdateSortSeries',
    method: 'post',
    data
  })
}
//更新天猫预计卖出
export function UpdateTmallGoodsSellExp(data) {
  return request({
    url: gConfig.url_server + 'api/tmallGoodsAdmin/UpdateSellExp',
    method: 'post',
    data
  })
}
//删除天猫商品
export function DelTmallGoodsInfoById(data) {
  return request({
    url: gConfig.url_server + 'api/tmallGoodsAdmin/DelInfoById',
    method: 'post',
    data
  })
}
//更新天猫商品排序
export function UpdateTmallGoodsInfoSort(data) {
  return request({
    url: gConfig.url_server + 'api/tmallGoodsAdmin/UpdateGoodsSort',
    method: 'post',
    data
  })
}

//获取商品排序分类
export function GetGoodsSortTypeListQ() {
  return request({
    url: gConfig.url_server + 'api/goodsSort/GetTypeList',
    method: 'get'
  })
}
//获取商品排序分类
export function GetGoodsSortTypeList() {
  return request({
    url: gConfig.url_server + 'api/goodsSortAdmin/GetTypeList',
    method: 'get'
  })
}
//添加商品排序
export function AddGoodsSort(data) {
  return request({
    url: gConfig.url_server + 'api/goodsSortAdmin/add',
    method: 'post',
    data
  })
}
//获取商品排序列表
export function GetGoodsSortList(data) {
  return request({
    url: gConfig.url_server + 'api/goodsSortAdmin/GetList' + getparam(data),
    method: 'get',
  })
}
//获取商品排序列表数量
export function GetGoodsSortListCount(data) {
  return request({
    url: gConfig.url_server + 'api/goodsSortAdmin/GetListCount' + getparam(data),
    method: 'get',
  })
}
//获取商品排序列表
export function GetGoodsSortListQ(data) {
  return request({
    url: gConfig.url_server + 'api/goodsSort/GetList' + getparam(data),
    method: 'get',
  })
}
//获取商品排序列表数量
export function GetGoodsSortListCountQ(data) {
  return request({
    url: gConfig.url_server + 'api/goodsSort/GetListCount' + getparam(data),
    method: 'get',
  })
}
//更新商品排序类别
export function UpdateGoodsSortType(data) {
  return request({
    url: gConfig.url_server + 'api/goodsSortAdmin/UpdateType',
    method: 'post',
    data
  })
}
//更新商品排序状态
export function UpdateGoodsSortStatus(data) {
  return request({
    url: gConfig.url_server + 'api/goodsSortAdmin/UpdateStatus',
    method: 'post',
    data
  })
}
//更新商品排序排序
export function UpdateGoodsSortSort(data) {
  return request({
    url: gConfig.url_server + 'api/goodsSortAdmin/UpdateSort',
    method: 'post',
    data
  })
}
//删除商品排序
export function DelGoodsSortInfoById(data) {
  return request({
    url: gConfig.url_server + 'api/goodsSortAdmin/DelInfoById',
    method: 'post',
    data
  })
}
//更新商品排序的排序
export function UpdateGoodsSortInfoSort(data) {
  return request({
    url: gConfig.url_server + 'api/goodsSortAdmin/UpdateGoodsSort',
    method: 'post',
    data
  })
}
//更新商品排序的 产品图
export function UpdateGoodsSortInfoImgStatus(data) {
  return request({
    url: gConfig.url_server + 'api/goodsSortAdmin/UpdateImgStatus',
    method: 'post',
    data
  })
}
//更新商品排序的 产品原图
export function UpdateGoodsSortInfoOrgImgStatus(data) {
  return request({
    url: gConfig.url_server + 'api/goodsSortAdmin/UpdateOrgImgStatus',
    method: 'post',
    data
  })
}
//更新商品排序的 模特图
export function UpdateGoodsSortInfoModelStatus(data) {
  return request({
    url: gConfig.url_server + 'api/goodsSortAdmin/UpdateModelStatus',
    method: 'post',
    data
  })
}
//更新商品排序的 视频
export function UpdateGoodsSortInfoVideoStatus(data) {
  return request({
    url: gConfig.url_server + 'api/goodsSortAdmin/UpdateVideoStatus',
    method: 'post',
    data
  })
}
//更新商品排序的 广告图
export function UpdateGoodsSortInfoAdImgStatus(data) {
  return request({
    url: gConfig.url_server + 'api/goodsSortAdmin/UpdateAdImgStatus',
    method: 'post',
    data
  })
}
//更新商品排序的 吊牌图
export function UpdateGoodsSortInfoTagImgStatus(data) {
  return request({
    url: gConfig.url_server + 'api/goodsSortAdmin/UpdateTagImgStatus',
    method: 'post',
    data
  })
}
//更新商品排序的 天猫首图
export function UpdateGoodsSortInfoTmallImgStatus(data) {
  return request({
    url: gConfig.url_server + 'api/goodsSortAdmin/UpdateTmallImgStatus',
    method: 'post',
    data
  })
}
//更新商品排序的 天猫详情
export function UpdateGoodsSortInfoTmallImgDetailsStatus(data) {
  return request({
    url: gConfig.url_server + 'api/goodsSortAdmin/UpdateTmallImgDetailsStatus',
    method: 'post',
    data
  })
}
//更新商品排序的 天猫上传
export function UpdateGoodsSortInfoTmallListStatus(data) {
  return request({
    url: gConfig.url_server + 'api/goodsSortAdmin/UpdateTmallListStatus',
    method: 'post',
    data
  })
}
//更新商品排序的 销售等级
export function UpdateGoodsSortInfoSaleLevel(data) {
  return request({
    url: gConfig.url_server + 'api/goodsSortAdmin/UpdateSaleLevel',
    method: 'post',
    data
  })
}
//更新商品排序的 PSD
export function UpdateGoodsSortInfoPsdStatus(data) {
  return request({
    url: gConfig.url_server + 'api/goodsSortAdmin/UpdatePsdStatus',
    method: 'post',
    data
  })
}
//获取商品排序状态列表
export function GetGoodsSorStatustList(data) {
  return request({
    url: gConfig.url_server + 'api/goodsSortAdmin/GetStatusList' + getparam(data),
    method: 'get',
  })
}
//获取商品排序列表数量
export function GetGoodsSortStatusListCount(data) {
  return request({
    url: gConfig.url_server + 'api/goodsSortAdmin/GetStatusListCount' + getparam(data),
    method: 'get',
  })
}
//获取商品状态列表
export function GetGoodsStatusList(data) {
  return request({
    url: gConfig.url_server + 'api/goodsStatusAdmin/GetList' + getparam(data),
    method: 'get',
  })
}
//获取商品状态数量
export function GetGoodsStatusListCount(data) {
  return request({
    url: gConfig.url_server + 'api/goodsStatusAdmin/GetListCount' + getparam(data),
    method: 'get',
  })
}
//门店商品库存列表
export function GetGoodsShopList(data) {
  return request({
    url: gConfig.url_server + 'api/shopGoodsStock/GetList' + getparam(data),
    method: 'get',
  })
}
//门店商品号码库存列表
export function GetGoodsSizeShopList(data) {
  return request({
    url: gConfig.url_server + 'api/shopGoodsStock/GetSizeList' + getparam(data),
    method: 'get',
  })
}
//保存门店商品号码库位
export function SaveGoodsSizeShopLoc(data) {
  return request({
    url: gConfig.url_server + 'api/shopGoodsStock/SaveSizeLoc',
    method: 'post',
    data
  })
}
//门店商品号码 - 货位库存汇总
export function GetGoodsSizeShopLocSum(data) {
  return request({
    url: gConfig.url_server + 'api/shopGoodsStock/GetLocSum' + getparam(data),
    method: 'get',
  })
}
//获取商品信息列表
export function GetGoodsInfoAdminList(data) {
  return request({
    url: gConfig.url_server + 'api/Admin/Goods/GetGoodsInfoAdminList' + getparam(data),
    method: 'get',
  })
}
//获取商品信息数量
export function GetGoodsInfoAdminCount(data) {
  return request({
    url: gConfig.url_server + 'api/Admin/Goods/GetGoodsInfoAdminCount' + getparam(data),
    method: 'get',
  })
}
//获取商品信息下拉列表
export function GetGoodsInfoAdminSelect() {
  return request({
    url: gConfig.url_server + 'api/Admin/Goods/GetGoodsInfoAdminSelect',
    method: 'get',
  })
}

//获取后台左侧菜单列表
export function GetLeftAdminPC() {
  return request({
    url: gConfig.url_server + 'api/index/GetLeftAdminPC',
    method: 'get',
  })
}
//独立商品编码 - 获取商品信息
export function AdminProStockInfoOnlyGetProInfoByGoodsItem(data) {
  return request({
    url: gConfig.url_server + 'api/Admin/ProStockInfoOnly/GetProInfoByGoodsItem',
    method: 'post',
    data
  })
}
//独立商品编码 - 批量添加
export function AdminProStockInfoOnlyAddList(data) {
  return request({
    url: gConfig.url_server + 'api/Admin/ProStockInfoOnly/AddList',
    method: 'post',
    data
  })
}





//获取客户信息
export function GetMemBase() {
  return request({
    url: gConfig.url_server + 'api/member/GetMemBase',
    method: 'get'
  })
}
//修改客户信息
export function EditMemBase(data) {
  return request({
    url: gConfig.url_server + 'api/member/EditMemBase',
    method: 'post',
    data
  })
}
//是否登录
export function IsLoginNew() {
  return request({
    url: gConfig.url_server + 'api/member/IsLoginNew',
    method: 'get'
  })
}
//登录
export function LoginAdmin(data) {
  return request({
    url: gConfig.url_server + 'api/member/Login',
    method: 'post',
    data
  })
}
//退出登录
export function LoginOut() {
  return request({
    url: gConfig.url_server + 'api/WxMini/LoginOut',
    method: 'get',
  })
}

//短信登录
export function LoginSMSCode(data) {
  return request({
    url: gConfig.url_server + 'api/member/LoginSMSCode',
    method: 'post',
    data
  })
}
//获取登录的验证码
export function GetLoginSMSCode(phone) {
  return request({
    url: gConfig.url_server + 'api/member/GetLoginSMSCode?phone=' + phone,
    method: 'get',
  })
}
//获取注册的验证码
export function GetRegisterSMSCode(phone) {
  return request({
    url: gConfig.url_server + 'api/member/GetRegisterSMSCode?phone=' + phone,
    method: 'get',
  })
}
//注册
export function RegisterSMSCode(data) {
  return request({
    url: gConfig.url_server + 'api/member/RegisterSMSCode',
    method: 'post',
    data
  })
}

//获取忘记密码的验证码
export function GetRetPwdSMSCode(phone) {
  return request({
    url: gConfig.url_server + 'api/member/GetRetPwdSMSCode?phone=' + phone,
    method: 'get',
  })
}
//忘记密码
export function RetPwdSMSCode(data) {
  return request({
    url: gConfig.url_server + 'api/member/RetPwdSMSCode',
    method: 'post',
    data
  })
}


//获取
export function GetLeftPC() {
  return request({
    url: gConfig.url_server + 'api/Index/GetLeftPC',
    method: 'get',
  })
}
export function GetLeftPC_4() {
  return request({
    url: gConfig.url_server + 'api/Index/GetLeftPC_4',
    method: 'get',
  })
}
//获取
export function GetGoodsSearchTagQ() {
  return request({
    url: gConfig.url_server + 'api/goods/GetGoodsSearchTagQ',
    method: 'get',
  })
}
//获取
export function GetGoodsFilter() {
  return request({
    url: gConfig.url_server + 'api/goods/GetGoodsFilter',
    method: 'get',
  })
}
//获取
export function GetMainPC(data) {
  return request({
    url: gConfig.url_server + 'api/Index/GetMainPC',
    method: 'get',
  })
}
//获取商品列表
export function GetList(data) {
  return request({
    url: gConfig.url_server + 'api/goods/GetListNew' + getparam(data),
    method: 'get',
  })
}

//更新活动专区 - 标签
export function UpdateTagHd(data) {
  return request({
    url: gConfig.url_server + 'api/Admin/Goods/UpdateTagHd',
    method: 'post',
    data
  })
}

//获取商品列表
export function GetFilterNew() {
  return request({
    url: gConfig.url_server + 'api/goods/GetFilterNew',
    method: 'get',
  })
}

//获取商品详情
export function GetGoodsInfoN(goodsId) {
  return request({
    url: gConfig.url_server + 'api/goods/GetGoodsInfoN?isPc=true&goodsId=' + goodsId,
    method: 'get',
  })
}
//加入购物袋
export function cart_AddN(data) {
  return request({
    url: gConfig.url_server + 'api/cart/AddN',
    method: 'post',
    data
  })
}

//购物车列表
export function cartGetList(data) {
  return request({
    url: gConfig.url_server + 'api/cart/GetList?selCarId=' + data + "&isSize=true&isWish=true",
    method: 'get',
  })
}

//修改数量
export function UpdateQtyByIdN(data) {
  return request({
    url: gConfig.url_server + 'api/cart/UpdateQtyByIdNew',
    method: 'post',
    data
  })
}
//修改号码
export function UpdateSizeById(data) {
  return request({
    url: gConfig.url_server + 'api/cart/UpdateSizeById',
    method: 'post',
    data
  })
}
//删除购物车
export function DelByIdN(id) {
  return request({
    url: gConfig.url_server + 'api/cart/DelByIdN?id=' + id,
    method: 'post'
  })
}
//商品列表 - 商品收藏
export function AddWishList(data) {
  return request({
    url: gConfig.url_server + 'api/goods/AddWishList',
    method: 'post',
    data
  })
}
//得到结算页订单信息
export function GetSettlementInfo(data) {
  return request({
    url: gConfig.url_server + 'api/cart/GetSettlementInfo?carId=' + data + "&act=",
    method: 'get',
  })
}

//获取历史地址
export function GetOrdShipList(data) {
  return request({
    url: gConfig.url_server + 'api/cart/GetOrdShipList?pageIndex=' + data + "&pageSize=50",
    method: 'get',
  })
}
//立即下单判断是否通过检查
export function CheckList(data) {
  return request({
    url: gConfig.url_server + 'api/cart/CheckList?selCarId=' + data,
    method: 'get',
  })
}

export function GetCarCoupon(data) {
  return request({
    url: gConfig.url_server + 'api/cart/GetCarCoupon?carPrice=' + data,
    method: 'get',
  })
}

//确认结算
export function AddOrderN(data) {
  return request({
    url: gConfig.url_server + 'api/cart/AddOrderN',
    method: 'post',
    data
  })
}
//支付订单
export function PayOrderPc(ordId) {
  return request({
    url: gConfig.url_server + 'api/Order/PayOrderPc?ordId=' + ordId,
    method: 'get',
  })
}
//订单详情
export function GetOrderInfoNew(id) {
  return request({
    url: gConfig.url_server + 'api/Order/GetOrderInfoNew?id=' + id,
    method: 'get',
  })
}
//取消订单
export function CancelOrder(id) {
  return request({
    url: gConfig.url_server + 'api/Order/CancelOrder?OrderId=' + id,
    method: 'get',
  })
}
//判断是否支付
export function GetOrdPayStatusByOid(id) {
  return request({
    url: gConfig.url_server + 'api/Order/GetOrdPayStatusByOid?oid=' + id,
    method: 'get',
  })
}

//判断是否支付
export function GetMenberOrderListNew(status, pageIndex, pageSize) {
  return request({
    url: gConfig.url_server + 'api/Order/GetMenberOrderListNew?status=' + status + "&pageIndex=" + pageIndex + "&pageSize=" + pageSize,
    method: 'get',
  })
}
//获取愿望单
export function GetWishList(page, pageSize) {
  return request({
    url: gConfig.url_server + 'api/goods/GetWishList?page=' + page + "&pageSize=" + pageSize,
    method: 'get',
  })
}
//删除愿望单
export function DelWishList(goodsId) {
  return request({
    url: gConfig.url_server + 'api/goods/DelWishList',
    method: 'post',
    data: { 'goodsId': goodsId }
  })
}

//获取浏览记录
export function GetGoodsRecentlyViewed(goodsIdStr) {
  return request({
    url: gConfig.url_server + 'api/goods/GetGoodsRecentlyViewed?goodsIdStr=' + goodsIdStr,
    method: 'get',
  })
}
//获取我的钱包余额、有效优惠券、积分
export function GetMemMoney() {
  return request({
    url: gConfig.url_server + 'api/member/GetMemMoney',
    method: 'get',
  })
}
//获取我的钱包记录
export function GetMemberBalanceList(PageIndex, PageSize) {
  return request({
    url: gConfig.url_server + 'api/member/GetMemberBalanceList?PageIndex=' + PageIndex + "&PageSize=" + PageSize,
    method: 'get',
  })
}
//获取我的优惠券
export function GetCoupoListNew(page, state, pageSize) {
  return request({
    url: gConfig.url_server + 'api/member/GetCoupoListNew?page=' + page + "&state=" + state + "&pageSize=" + pageSize,
    method: 'get',
  })
}
//获取我的积分记录
export function GetMemberIntegralList(PageIndex, PageSize) {
  return request({
    url: gConfig.url_server + 'api/member/GetMemberIntegralList?PageIndex=' + PageIndex + "&PageSize=" + PageSize,
    method: 'get',
  })
}
//门店下单
export function ShopAddOrder(data) {
  return request({
    url: gConfig.url_server + 'api/Admin/StoreOrder/AddOrder',
    method: 'post',
    data
  })
}


























